import React, { useContext, useEffect, useState } from "react";
import LeftColumn from "./LeftColumn";
import Stepper from "./Stepper";
import "../App.css";
import { DataContext } from "../contexts/data.context";

// Функция для генерации данных для таблицы
function generateRows(mortgageData, rentalData, realizationData) {
  const startDate = new Date(2024, 7, 1); // Начальная дата: 01.08.2024
  const numberOfRows = realizationData.months || 12; // Количество строк (например, на 12 месяцев)

  // Данные, установленные пользователем
  const propertyCost = mortgageData.propertyCost; // Стоимость объекта
  const initialPayment = mortgageData.initialPayment; // Первоначальный взнос
  const revenueFromSale = realizationData.objectValue; // Выручка от реализации объекта
  const monthlyPayment = mortgageData.monthlyPayment; // Ежемесячный платёж
  const loanAmount = propertyCost - initialPayment; // Основной долг
  const monthlyInterestRate = parseFloat(
    ((mortgageData.interestRate / 100) / 12).toFixed(12)
  ); // Месячная процентная ставка
  const rentIncome = rentalData.rentalIncome; // Аренда
  const rentStartMonth = rentalData.startMonth; // Начало аренды с 6 месяца

  let remainingDebt = loanAmount; // Начальный остаток долга
  let accumulatedCreditExpenses = initialPayment; // Накопительные расходы по кредиту
  let accumulatedRent = 0; // Накопительная аренда

  const rows = Array.from({ length: numberOfRows }, (_, index) => {
    const id = index + 1; // № п/п
    const month = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + index,
      1
    ).toLocaleDateString("ru-RU", { year: "numeric", month: "long" });

    // Остаток основного долга с учетом процентной ставки
    const interestForThisMonth = parseFloat(
      (remainingDebt * monthlyInterestRate).toFixed(12)
    ); // Проценты за этот месяц
    const monthlyCreditExpenses = parseFloat(
      (monthlyPayment - interestForThisMonth).toFixed(12)
    ); // Погашение долга
    remainingDebt -= monthlyCreditExpenses; // Уменьшение основного долга

    // Накопительные расходы по кредиту
    if (remainingDebt > 0) {
      accumulatedCreditExpenses += monthlyPayment; // Накопительные расходы по кредиту
    }

    // Накопительная аренда
    if (id >= rentStartMonth) {
      accumulatedRent += rentIncome; // Накопительная аренда
    }

    // Чистый доход
    const netIncome =
      revenueFromSale -
      (propertyCost - initialPayment) -
      accumulatedCreditExpenses +
      accumulatedRent;

    return {
      id, // № п/п
      month, // Месяц
      monthlyPayment, // Ежемесячный платёж
      debt: Math.round(remainingDebt), // Остаток основного долга (округлено)
      creditExpenses: Math.round(accumulatedCreditExpenses), // Расходы по кредиту накопит. итого
      rent: Math.round(accumulatedRent), // Аренда накопит. итогом
      netIncome: Math.round(netIncome), // Чистый доход
    };
  });

  return rows;
}

function FV(rate, nper, pmt, pv, type = 0) {
  if (rate === 0) {
    return -(pv + pmt * nper);
  } else {
    let pow = Math.pow(1 + rate, nper);
    return -(pv * pow + (pmt * (1 + rate * type) * (pow - 1)) / rate);
  }
}

function sum(array) {
  return array.reduce((a, b) => a + b, 0);
}

function max(array) {
  return Math.max(...array);
}

function filter(arrayP, arrayK, C20) {
  let result = [];
  for (let i = 0; i < arrayP.length; i++) {
    if (arrayK[i] <= C20) {
      result.push(arrayP[i]);
    }
  }
  return result;
}

function calculateReturnPercentage(F5, H22, H21, H26, H8, H9) {
  const text = "Доходность при кредитном плече";

  try {
    let base0 = Math.round((H22 / H21) * 10000) / 10000;

    let denominator = H8 - H9;
    if (denominator === 0) throw new Error("Division by zero");

    let dop = Math.round((H26 / denominator) * 10000) / 10000;

    // Final result based on F5
    let result = F5 ? base0 + dop : base0;

    return {text, result};
  } catch (e) {
    return {text}
  }
}

function calculateBreakEven(J_values, P_values) {
  let x;

  // Проверяем, есть ли отрицательные значения в P_values
  const hasNegativeP = P_values.some(p => p < 0);

  if (!hasNegativeP) {
      // Если нет отрицательных значений
      x = Math.max(...J_values);
  } else {
      // Если есть отрицательные значения
      const median_J = median(J_values);

      // Фильтруем J_values, где P_values > 0 и J_values <= median_J
      const filtered_J_values = J_values.filter((val, idx) => P_values[idx] > 0 && val <= median_J);

      if (filtered_J_values.length > 0) {
          x = Math.max(...filtered_J_values) - 1;
      } else {
          x = Math.max(...J_values) - 1;
      }
  }

  // Находим соответствующее значение из P_values для x в J_values
  const index = J_values.indexOf(x);
  let C21 = null; // Значение для C21

  if (index >= 0) {
      C21 = P_values[index];
  }

  const D21 = x; // Значение для D21

  // Возвращаем значения для ячеек B21, C21 и D21
  return { C21, D21 };
}

// Функция для вычисления медианы
function median(values) {
  const sorted = [...values].sort((a, b) => a - b);
  const len = sorted.length;

  if (len % 2 === 1) {
      return sorted[(len - 1) / 2];
  } else {
      const mid1 = sorted[len / 2 - 1];
      const mid2 = sorted[len / 2];
      return (mid1 + mid2) / 2;
  }
}

function calculateAnnualizedReturn(E19, E8, C20) {
  // Ensure inputs are numbers
  E19 = Number(E19);
  E8 = Number(E8);
  C20 = Number(C20);

  // Check for invalid inputs
  if (E8 === 0 || C20 === 0 || isNaN(E19) || isNaN(E8) || isNaN(C20)) {
      return null; // Return null to indicate an error
  }

  // Calculate the annualized return percentage
  let result = ((E19 - E8) / E8) * (12 / C20);

  // Return the result
  return result;
}

function calculateRentalIncome(P_values, K_values, C20) {
  // Фильтруем массив P_values, выбирая только те элементы, где соответствующий элемент в K_values <= C20
  let filteredValues = [];
  for (let i = 0; i < P_values.length; i++) {
      if (K_values[i] <= C20) {
          filteredValues.push(P_values[i]);
      }
  }

  // Находим максимальное значение из отфильтрованных значений
  let maxValue = 0;
  if (filteredValues.length > 0) {
      maxValue = Math.max(...filteredValues);
  }

  // Возвращаем описание и рассчитанный доход
  return maxValue;
}

function calculateIncome(F5, E8, E9, G5, C20) {
  if (F5 === true) {
      return (E8 - E9) * G5 * C20 / 12;
  } else {
      return 0;
  }
}

function fillCells(
  mortgageData,
  rentalData,
  realizationData,
  isDepositChecked,
  paymentSchedule,
  updateCell
) {
  const F5 = isDepositChecked;
  updateCell("F5", F5);

  const E19 = realizationData.objectValue;
  updateCell("E19", E19);

  const E9 = mortgageData.initialPayment;
  updateCell("E9", E9);

  const E20 = realizationData.realizationPeriod - 1;
  updateCell("E20", E20);

  const C12 = mortgageData.monthlyPayment;
  updateCell("C12", C12);

  const E10 = ((mortgageData.interestRate / 100) / 12);
  updateCell("E10", E10);

  const E8 = mortgageData.propertyCost;
  updateCell("E8", E8);

  const G5 = 20;
  updateCell("G5", G5);

  const C20 = realizationData.realizationPeriod;
  updateCell("C20", C20);

  const P = paymentSchedule?.map((item) => item.rent); // Аренда накопит. итогом
  updateCell("P", P);

  const Q = paymentSchedule?.map((item) => item.netIncome); // Чистый доход
  updateCell("Q", Q);

  const K = paymentSchedule?.map((item) => item.id); // Id
  updateCell("K", K);

  // ---------- Расчеты ----------------------
  // Base calculation
  let base =
    E19 -
    E9 -
    E20 * C12 +
    FV(E10, E20, -C12, E8 - E9, 0) +
    sum([max(filter(P, K, C20))]);

  // Dop calculation
  let dop = ((E8 - E9) * (G5 / 100) * C20) / 12;

  // Denominator
  let result = F5 ? base + dop : base;
  let H22 = result;
  updateCell("H22", H22);
  updateCell("C24", H22)

  let H16 =
    realizationData.realizationPeriod > 0
      ? Math.max(realizationData.realizationPeriod - 1, 0)
      : 0;
  updateCell("H16", H16);

  // Рассчитываем доходность в рублях, %
  const totalMortgagePayments = mortgageData.monthlyPayment * H16;
  const H21 = mortgageData.initialPayment + totalMortgagePayments;
  updateCell("H21", H21);

  let H26 = calculateIncome(F5, E8, E9, (G5 / 100), C20);
  updateCell("H26", H26);

  let H8 = mortgageData.propertyCost;
  updateCell("H8", H8);

  let H9 = mortgageData.initialPayment;
  updateCell("H9", H9); // Первоначальный взнос

  let H23 = calculateReturnPercentage(F5, H22, H21, H26, H8, H9).result
  updateCell("H23", H23);
  updateCell("C25", H23);

  let H25 = calculateRentalIncome(P, K, C20);
  updateCell("H25", H25);

  let C26 = calculateAnnualizedReturn(E19, E8, C20);
  updateCell("C26", C26);

  let C21 = calculateBreakEven(K, Q).C21
  updateCell("C21", C21);

  let D21 = calculateBreakEven(K, Q).D21
  updateCell("D21", D21);
}

function MainContent() {
  const { setPaymentSchedule, updateCell } = useContext(DataContext);

  const [mortgageData, setMortgageData] = useState({});
  const [rentalData, setRentalData] = useState({});
  const [realizationData, setRealizationData] = useState({});
  const [isDepositChecked, setIsDepositChecked] = useState(false);

  useEffect(() => {
    const rows = generateRows(mortgageData, rentalData, realizationData);

    setPaymentSchedule(rows);
    // Заполняем ячейки
    fillCells(
      mortgageData,
      rentalData,
      realizationData,
      isDepositChecked,
      rows,
      updateCell
    );
  }, [
    mortgageData,
    rentalData,
    realizationData,
    setPaymentSchedule,
    isDepositChecked,
    updateCell,
  ]);

  const handleDepositChange = (event) => {
    setIsDepositChecked(event.target.checked); // Обработчик изменения чекбокса
  };

  return (
    <div className="main-content">
      <div className="top-section">
        <h1>Калькулятор инвестиций</h1>
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="x1-checkbox"
            checked={isDepositChecked}
            onChange={handleDepositChange}
          />
          <label htmlFor="x1-checkbox">Депозит 20%</label>
        </div>
      </div>

      <div className="content-columns">
        <LeftColumn
          setMortgageData={setMortgageData}
          setRentalData={setRentalData}
          setRealizationData={setRealizationData}
          mortgageData={mortgageData}
          rentalData={rentalData}
          realizationData={realizationData}
        />

        <Stepper
          mortgageData={mortgageData}
          rentalData={rentalData}
          realizationData={realizationData}
          isDepositChecked={isDepositChecked}
        />
      </div>
    </div>
  );
}

export default MainContent;
