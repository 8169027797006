import React, { useContext, useEffect, useState } from "react";
import "../App.css";
import { DataContext } from "../contexts/data.context";

function PaymentSchedule({ mortgageData, rentalData, realizationData }) {
  const { paymentSchedule } = useContext(DataContext);

  const [visibleRows, setVisibleRows] = useState(6);
  const showMoreRows = () => {
    setVisibleRows(visibleRows + 2);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\u00A0/g, " ");
  };

  return (
    <div className="payment-schedule">
      <div className="table-header">
        <h2>График платежей</h2>
        <p className="start-date">
          Начальная дата:
          <br />
          <strong className="start-date">01.08.2024</strong>
        </p>
      </div>

      <div className="table-scroll">
        <div className="table-row table-header-row">
          <div className="table-cell-top">№ п/п</div>
          <div className="table-cell-top">Месяц</div>
          <div className="table-cell-top">Ежемесячный платёж</div>
          <div className="table-cell-top">Остаток основного долга</div>
          <div className="table-cell-top">
            Расходы по кредиту накопит. итого
          </div>
          <div className="table-cell-top">Аренда накопит. итогом</div>
          <div className="table-cell-top">Чистый доход</div>
        </div>

        {paymentSchedule.slice(0, visibleRows).map((row) => (
          <div className="table-row" key={row.id}>
            <div className="table-cell">{row.id}</div>
            <div className="table-cell">{row.month}</div>
            <div className="table-cell">
              {formatCurrency(row.monthlyPayment)}
            </div>
            <div className="table-cell">{formatCurrency(row.debt)}</div>
            <div className="table-cell">
              {formatCurrency(row.creditExpenses)}
            </div>
            <div className="table-cell">{formatCurrency(row.rent)}</div>
            <div className="table-cell">{formatCurrency(row.netIncome)}</div>
          </div>
        ))}
      </div>

      <div className="table-btn">
        {visibleRows < paymentSchedule.length && (
          <button onClick={showMoreRows} className="show-more-btn">
            Показать еще
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7797 5.9668L9.43306 10.3135C8.91973 10.8268 8.07973 10.8268 7.56639 10.3135L3.21973 5.9668"
                stroke="#1E1E1E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default PaymentSchedule;
