import React, { createContext, useCallback, useState } from "react";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [cells, setCells] = useState({});

  // Функция для обновления значения ячейки
  const updateCell = useCallback((cellName, value) => {
    setCells((prevCells) => ({
      ...prevCells,
      [cellName]: value,
    }));
  }, []);

  // Функция для получения значения ячейки
  const getCell = (cellName) => {
    return cells[cellName];
  };

  return (
    <DataContext.Provider value={{ paymentSchedule, setPaymentSchedule, cells, updateCell, getCell }}>
      {children}
    </DataContext.Provider>
  );
};
