import React, { useContext, useEffect, useMemo, useState } from "react";
import "../App.css";
import { DataContext } from "../contexts/data.context";

function DetailCard({
  mortgageData,
  rentalData,
  realizationData,
  isDepositChecked,
}) {
  const { paymentSchedule, getCell } = useContext(DataContext);

  // Рассчитываем разницу между выручкой от реализации объекта и стоимостью объекта
  const factDifference =
    realizationData.objectValue - mortgageData.propertyCost;

  // Рассчитываем ежемесячную процентную ставку
  const monthlyInterestRate = (mortgageData.interestRate / 100) / 12;

  // Определяем количество оплаченных месяцев
  const paidMonths =
    realizationData.realizationPeriod > 0
      ? Math.max(realizationData.realizationPeriod - 1, 0)
      : 0;

  // Рассчитываем сумму ипотечных платежей за указанный период
  const totalMortgagePayments = mortgageData.monthlyPayment * paidMonths;

  // Сумма всех инвестиций (первоначальный взнос + ипотечные платежи за указанный период)
  const totalInvestments = mortgageData.initialPayment + totalMortgagePayments;

  // Функция для вычисления будущей стоимости (FV)
  function FV(rate, nper, pmt, pv, type = 0) {
    if (rate === 0) {
      return -(pv + pmt * nper);
    } else {
      let pow = Math.pow(1 + rate, nper);
      return -(pv * pow + (pmt * (1 + rate * type) * (pow - 1)) / rate);
    }
  }

  // Рассчитываем остаток по кредиту через указанный период
  const calculateRemainingDebt = () => {
    const loanAmount = mortgageData.propertyCost - mortgageData.initialPayment; // Основной долг
    const monthlyPayment = mortgageData.monthlyPayment; // Ежемесячный платеж
    const periodsPassed = realizationData.realizationPeriod - 1;
    const rate = monthlyInterestRate; // Месячная процентная ставка

    // Используем функцию FV
    let futureValue = FV(rate, periodsPassed, -monthlyPayment, loanAmount, 0);

    // Проверяем на ошибки
    if (isNaN(futureValue) || !isFinite(futureValue)) {
      futureValue = "-";
    } else {
      // Округляем до целого числа
      futureValue = Math.round(futureValue);
    }

    return futureValue;
  };

  const remainingDebt = calculateRemainingDebt();

  function calculateRentalIncome() {
    if (rentalData.startMonth > 0 && realizationData.realizationPeriod > rentalData.startMonth) {
      return (
        (realizationData.realizationPeriod - rentalData.startMonth) *
        rentalData.rentalIncome
      );
    } else {
      return 0;
    }
  }  

  return (

<div className="details-card">
<h2 className="mobile-detail-h2">Детализация</h2>
    <div className="detail-card">
      <div className="detail-section">
        <div className="detail-wrapper">
          <div className="detail-left">
            <p>Стоимость объекта</p>
            <span className="currency-sign">
              {new Intl.NumberFormat("ru-RU")
                .format(mortgageData.propertyCost)
                .replace(/\u00A0/g, " ")}{" "}
              ₽
            </span>
          </div>
          <div className="detail-left">
            <p>Срок кредита</p>
            <span>{mortgageData.loanPeriod} мес</span>
          </div>
        </div>
        <div className="detail-right">
          <p>Первоначальный взнос</p>
          <span className="currency-sign">
            {new Intl.NumberFormat("ru-RU")
              .format(getCell("H9"))
              .replace(/\u00A0/g, " ")}{" "}
            ₽
          </span>
        </div>
      </div>

      <div className="detail-section">
        <div className="detail-wrapper">
          <div className="detail-left">
            <p>Сдача объекта в аренду (руб./мес.)</p>
            <span className="currency-sign">
              {new Intl.NumberFormat("ru-RU")
                .format(rentalData.rentalIncome)
                .replace(/\u00A0/g, " ")}{" "}
              ₽
            </span>
          </div>
          <div className="detail-left">
            <p>Доп. доход от аренды на месяц реализации</p>
            <span className="currency-sign">
              {new Intl.NumberFormat("ru-RU")
                .format(calculateRentalIncome())
                .replace(/\u00A0/g, " ")}{" "}
              ₽
            </span>
          </div>
        </div>
        <div className="detail-right">
          <p>С какого месяца</p>
          <span>{rentalData.startMonth} мес</span>
        </div>
      </div>

      <div className="detail-section">
          <div className="detail-left">
            <p>{`Платежей по кредиту за ${realizationData.realizationPeriod} мес.`}</p>
            <span className="currency-sign">
              {new Intl.NumberFormat("ru-RU")
                .format(
                  isNaN(mortgageData.monthlyPayment * paidMonths) ||
                    mortgageData.monthlyPayment * paidMonths < 0
                    ? 0
                    : mortgageData.monthlyPayment * paidMonths
                )
                .replace(/\u00A0/g, " ")}{" "}
              ₽
            </span>
          </div>
          <div className="detail-right">
            <p>
              Остаток по кредиту через {realizationData.realizationPeriod} мес.
            </p>
            <span className="currency-sign">
              {typeof remainingDebt === "number" && !isNaN(remainingDebt)
                ? new Intl.NumberFormat("ru-RU")
                    .format(remainingDebt < 0 ? 0 : remainingDebt)
                    .replace(/\u00A0/g, " ")
                : 0}{" "}
              ₽
            </span>
          </div>
        </div>
        
      <div className="detail-section">
        <div className="detail-wrapper">
          <div className="detail-left">
            <p>Выручка от реализации объекта</p>
            <span className="currency-sign">
              {new Intl.NumberFormat("ru-RU")
                .format(realizationData.objectValue)
                .replace(/\u00A0/g, " ")}{" "}
              ₽
            </span>
          </div>
          <div className="detail-left">
              <p>Сумма всех инвестиций</p>
              <span className="currency-sign">
                {new Intl.NumberFormat("ru-RU")
                  .format(isNaN(totalInvestments) ? 0 : totalInvestments)
                  .replace(/\u00A0/g, " ")}{" "}
                ₽
              </span>
            </div>
        </div>
        <div className="detail-wrapper">
          <div className="detail-right">
            <p>Факт. разница от тела кредита</p>
            <span className="currency-sign">
              {new Intl.NumberFormat("ru-RU")
                .format(factDifference)
                .replace(/\u00A0/g, " ")}{" "}
              ₽
            </span>
          </div>
          <div className="detail-right">
            <p>Доходность в рублях, %</p>
            <div className="percent">
              <span className="currency-sign">
                {new Intl.NumberFormat("ru-RU")
                  .format(Math.round(getCell("H22") || 0))
                  .replace(/\u00A0/g, " ")}
                ₽
              </span>
              <span className="highlight">
                {getCell("H23") != null
                  ? (getCell("H23") * 100).toFixed(2)
                  : "0.00"}
                %
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="detail-section">
        <div className="detail-left">
          <p>Доход от аренды</p>
          <span className="currency-sign">
            {new Intl.NumberFormat("ru-RU")
              .format(getCell("H25") || 0)
              .replace(/\u00A0/g, " ")}
            ₽
          </span>
        </div>
        <div className="detail-right last">
          <p>Доход от депозита под 20%</p>
          <span className="currency-sign">
            {new Intl.NumberFormat("ru-RU")
              .format(getCell("H26") || 0)
              .replace(/\u00A0/g, " ")}{" "}
            ₽
          </span>
        </div>
      </div>
    </div>
</div>
  );
}

export default DetailCard;
