import React from "react";
import MortgageCard from "./MortgageCard";
import RentalIncomeCard from "./RentalIncomeCard";
import RealizationCard from "./RealizationCard";
import ProfitabilityCard from "./ProfitabilityCard";
import "../App.css";

function LeftColumn({ setMortgageData, setRentalData, setRealizationData, mortgageData, rentalData, realizationData }) {
  return (
    <div className="left-column">
      <MortgageCard setMortgageData={setMortgageData} />
      <RentalIncomeCard setRentalData={setRentalData} />
      <RealizationCard setRealizationData={setRealizationData} />
      <ProfitabilityCard
        mortgageData={mortgageData}
        rentalData={rentalData}
        realizationData={realizationData}
      />
    </div>
  );
}

export default LeftColumn;
