import React, { useEffect, useRef } from "react";
import "../App.css";

function CustomSlider({ min, max, value, onChange, step = 1 }) {
  const sliderRef = useRef(null);

  const updateSliderBackground = (slider) => {
    const valuePercent =
      ((slider.value - slider.min) / (slider.max - slider.min)) * 100;
    slider.style.background = `linear-gradient(to right, rgba(29, 235, 149, 1) ${valuePercent}%, #e0e0e0 ${valuePercent}%)`;
  };

  useEffect(() => {
    if (sliderRef.current) {
      updateSliderBackground(sliderRef.current);
    }
  }, [value]);

  return (
    <input
      ref={sliderRef}
      type="range"
      min={min}
      max={max}
      value={value}
      step={step}
      onChange={(e) => {
        onChange(Number(e.target.value));
        updateSliderBackground(e.target);
      }}
      className="slider"
    />
  );
}

export default CustomSlider;
