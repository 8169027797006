import React, { useState } from "react";
import CustomBarChart from "./CustomBarChart";
import "../App.css";

function IncomeLossCard({ paymentSchedule }) {
  const [interval, setInterval] = useState("1 год");

  return (
    <div className="income-loss-card">
      <h2 className="chart-title">График доход/убыток</h2>

      <CustomBarChart paymentSchedule={paymentSchedule} interval={interval} />

      <div className="chart-footer">
        <div className="chart-items chart-left">
          <div className="items-item">
            <span className="items-color income-color"></span> Доход
          </div>
          <div className="items-item">
            <span className="items-color expense-color"></span> Убыток
          </div>
        </div>
        <div className="chart-right">
          <div className="start-date">
            Начальная дата: <strong>01.08.2024</strong>
          </div>

          <div className="interval-selector">
            <label htmlFor="interval-select">Промежуток: </label>
            <div className="custom-select">
              <select
                id="interval-select"
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
              >
                {/* <option value="30 дней">30 д</option> */}
                <option value="1 год">1 год</option>
                <option value="3 года">3 года</option>
                <option value="10 лет">10 лет</option>
                <option value="Весь срок">Весь срок</option>
              </select>

              <div className="custom-arrow">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.7797 5.9668L9.43306 10.3135C8.91973 10.8268 8.07973 10.8268 7.56639 10.3135L3.21973 5.9668"
                    stroke="#1deb95"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncomeLossCard;
