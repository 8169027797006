import React, { useState, useEffect, useContext } from "react";
import CustomSlider from "./CustomSlider";
import "../App.css";
import { DataContext } from "../contexts/data.context";

function RealizationCard({ setRealizationData }) {
  const { getCell } = useContext(DataContext);

  const [objectValue, setObjectValue] = useState(0); // Стоимость объекта при реализации
  const [realizationPeriod, setRealizationPeriod] = useState(0); // Период реализации объекта (мес.)
  const [breakEvenPoint, setBreakEvenPoint] = useState(38300000); // Точка безубыточности
  const [months, setMonths] = useState(300); // Количество месяцев (из ипотеки)
  const [isOpen, setIsOpen] = useState(false);

  // Передача данных реализации в родительский компонент
  useEffect(() => {
    if (typeof setRealizationData === "function") {
      setRealizationData({
        objectValue,
        realizationPeriod,
        breakEvenPoint,
        months,
      });
    }
  }, [
    objectValue,
    realizationPeriod,
    breakEvenPoint,
    months,
    setRealizationData,
  ]);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  const isFilled = (value, initialValue) => {
    return value !== initialValue ? "filled" : "";
  };

  const formatNumberWithSpaces = (number) => {
    return new Intl.NumberFormat("ru-RU")
      .format(number)
      .replace(/\u00A0/g, " ");
  };

  const isSpanBold = (currentValue, initialValue) => {
    return currentValue !== initialValue ? "filled" : "";
  };

  return (
    <div className={`small-card card ${isOpen ? "open" : ""}`}>
      <h2 className="card-header" onClick={toggleCard}>
        Реализация объекта
      </h2>

      <div className={`card-content ${isOpen ? "open" : ""}`}>
        <div className="two-column-small-card">
          <div className="small-left-column">
            <div className="field-three">
              <label>В рублях</label>
              <div className="input-wrapper">
                <div className="slider-input">
                  <input
                    type="text"
                    value={
                      objectValue === ""
                        ? ""
                        : formatNumberWithSpaces(objectValue)
                    }
                    onChange={(e) => {
                      const value = e.target.value.replace(/\s+/g, "");
                      setObjectValue(value === "" ? "" : parseInt(value));
                    }}
                    step="100000"
                    min="1000000"
                    max="50000000"
                    placeholder="Введите стоимость объекта"
                    className={`slider-input-field interest-input ${isFilled(
                      objectValue,
                      40000000
                    )}`}
                    // style={{
                    //   width: `${Math.max(objectValue.toString().length * 0.7, 5)}em`,
                    // }}
                  />
                  <span
                    className={`currency-sign ${isFilled(
                      objectValue,
                      40000000
                    )}`}
                  >
                    ₽
                  </span>
                  <svg
                    width="26"
                    height="23"
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.0406 6.10994L15.9408 14.2106C15.2306 14.9199 13.9835 15.8494 13.1003 16.3263L11.442 17.2217C11.0755 17.4199 10.7497 17.5158 10.4483 17.5158C10.0363 17.5158 9.68045 17.3289 9.47245 17.0023C9.21001 16.5904 9.2287 16.0566 9.5277 15.4163L10.3695 13.6158C10.7993 12.6944 11.7003 11.4083 12.4202 10.6884L20.52 2.58775L24.0406 6.10994ZM25.2203 4.931L25.2658 4.8855C25.7343 4.41805 25.9983 3.78385 25.9998 3.12203C26.0013 2.4602 25.7403 1.82479 25.274 1.35519C24.3363 0.416752 22.6861 0.421627 21.7445 1.36413L21.699 1.40881L25.2203 4.931ZM20.3689 21.5158C20.3698 21.2946 20.2828 21.0821 20.1271 20.925C19.9714 20.768 19.7597 20.6791 19.5385 20.6781L0.837201 20.5911H0.833951C0.617228 20.5973 0.411433 20.6877 0.260148 20.843C0.108864 20.9983 0.023968 21.2064 0.02344 21.4232C0.0229119 21.64 0.106793 21.8485 0.257319 22.0045C0.407846 22.1606 0.613198 22.2519 0.829888 22.2592L19.5312 22.3453H19.5353C19.7558 22.3457 19.9675 22.2586 20.1238 22.1031C20.2801 21.9475 20.3682 21.7363 20.3689 21.5158Z"
                      fill="#E7E9F0"
                    />
                  </svg>
                </div>
                <CustomSlider
                  min={1000000}
                  max={50000000}
                  value={objectValue}
                  onChange={setObjectValue}
                />
              </div>
            </div>

            <div className="field-two">
              <label>Точка безубыточности</label>
              <div className="input-wrapper">
                <span className="break-even-point">
                  <strong>
                    {new Intl.NumberFormat("ru-RU")
                      .format(getCell("C21") || 0)
                      .replace(/\u00A0/g, " ")}{" "}
                    ₽
                  </strong>
                </span>
              </div>
            </div>
          </div>

          <div className="small-right-column">
            <div className="field-three">
              <label>На каком сроке (мес.)</label>
              <div className="input-wrapper">
                <div className="slider-input">
                  <input
                    type="number"
                    value={realizationPeriod === "" ? "" : realizationPeriod}
                    onChange={(e) => {
                      let value = parseInt(e.target.value);
                      if (isNaN(value)) {
                        value = "";
                      } else if (value < 1) {
                        value = 1;
                      } else if (value > 36) {
                        value = 36;
                      }
                      setRealizationPeriod(value);
                    }}
                    step="1"
                    min="6"
                    max="36"
                    placeholder="6"
                    className={`slider-input-field ${isSpanBold(
                      realizationPeriod,
                      12
                    )} month`}
                    // style={{
                    //   width: `${Math.max(
                    //     realizationPeriod.toString().length + 1,
                    //     2
                    //   )}ch`,
                    // }}
                  />
                  <span
                    className={`realization-period-unit ${isSpanBold(
                      realizationPeriod,
                      12
                    )}`}
                  >
                    мес
                  </span>
                </div>
                <CustomSlider
                  min={6}
                  max={36}
                  step={1}
                  value={realizationPeriod === "" ? 6 : realizationPeriod}
                  onChange={setRealizationPeriod}
                />
              </div>
            </div>

            <div className="field-two">
              <label>Количество месяцев</label>
              <div className="input-wrapper">
                <span className="total-months">
                  <strong>{getCell("D21") || 0} мес</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealizationCard;
