import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import { DataContext } from "../contexts/data.context";

function ProfitabilityCard({ mortgageData, rentalData, realizationData }) {
  const { getCell } = useContext(DataContext);

  const [netIncome, setNetIncome] = useState(0);
  const [cashReturn, setCashReturn] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const checkDataValidity = () => {
    return (
      mortgageData &&
      !isNaN(mortgageData.initialPayment) &&
      !isNaN(mortgageData.monthlyPayment) &&
      !isNaN(mortgageData.loanPeriod) &&
      rentalData &&
      !isNaN(rentalData.rentalIncome) &&
      !isNaN(rentalData.startMonth) &&
      realizationData &&
      !isNaN(realizationData.objectValue) &&
      !isNaN(realizationData.realizationPeriod)
    );
  };

  useEffect(() => {
    if (checkDataValidity()) {
      console.log("Все данные для расчета валидны");
      setIsDataLoaded(true);

      // Вычисляем доход от аренды
      const rentalMonths =
        realizationData.realizationPeriod - rentalData.startMonth; // Количество месяцев аренды
      const totalRentalIncome =
        rentalMonths > 0 ? rentalData.rentalIncome * rentalMonths : 0;

      // Выручка от реализации объекта
      const totalRealizationIncome = realizationData.objectValue;

      // Общий доход
      const totalIncome = totalRentalIncome + totalRealizationIncome;

      // Общая сумма платежей по ипотеке
      const totalMortgagePayments =
        mortgageData.monthlyPayment * realizationData.realizationPeriod;

      // Остаток по кредиту (можно использовать функцию для расчета)
      const remainingDebt = mortgageData.remainingDebt || 0;

      // Расчет чистого дохода
      const netIncomeCalculated =
        totalIncome -
        mortgageData.initialPayment -
        totalMortgagePayments -
        remainingDebt;

      setNetIncome(netIncomeCalculated);

      if (mortgageData.initialPayment !== 0) {
        const cashReturnCalculated =
          (netIncomeCalculated / mortgageData.initialPayment) * 100;
        console.log(
          "Вычисленная доходность за наличные:",
          cashReturnCalculated
        );
        setCashReturn(cashReturnCalculated);
      } else {
        setCashReturn(0);
      }

      // Выводим значения для отладки после завершения расчетов
      console.log("Выручка от реализации объекта:", totalRealizationIncome);
      console.log("Арендный доход:", totalRentalIncome);
      console.log("Чистый доход:", netIncomeCalculated);
    } else {
      console.log("Некорректные или отсутствующие данные для расчета.");
      setIsDataLoaded(false);
    }
  }, [mortgageData, rentalData, realizationData]);

  const toggleCard = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`small-card card ${isOpen ? "open" : ""}`}>
      <h2 className="card-header" onClick={toggleCard}>
        Доходность
      </h2>

      <div className={`card-content ${isOpen ? "open" : ""}`}>
        <div className="row">
          <div className="row-container profit-wrap">
            <label>Чистый доход</label>
            <div className="input-wrapper">
              <span className="profit-amount">
                <strong>
                  {netIncome
                    ? new Intl.NumberFormat("ru-RU")
                        .format(Math.round(getCell("C24") || 0))
                        .replace(/\u00A0/g, " ")
                    : "0"}{" "}
                  ₽
                </strong>
              </span>
            </div>
          </div>

          <div className="row-container profit-wrap">
            <label>Доходность при кредитном плече</label>
            <div className="input-wrapper">
              <span className="profit-percentage">
                <strong>
                  {getCell("H23") != null
                    ? (getCell("H23") * 100).toFixed(2)
                    : "0.00"}
                  %{" "}
                </strong>
              </span>
            </div>
          </div>

          <div className="profit-wrap">
            <label>Доходность за нал (% годовых)</label>
            <div className="input-wrapper">
              <span className="profit-percentage">
                <strong>
                  {getCell("C26") != null
                    ? (getCell("C26") * 100).toFixed(2)
                    : "0.00"}
                  %{" "}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfitabilityCard;
