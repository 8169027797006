import React, { useState, useEffect } from "react";
import "../App.css";
import CustomSlider from "./CustomSlider";

function MortgageCard({ setMortgageData }) {
  const initialPrice = 0;
  const initialDownPayment = 0;
  const initialMortgagePeriod = 0;
  const initialInterestRate = 0;
  const initialPaymentType = "annuity";

  const [price, setPrice] = useState(initialPrice);
  const [downPayment, setDownPayment] = useState(initialDownPayment);
  const [mortgagePeriod, setMortgagePeriod] = useState(initialMortgagePeriod);
  const [interestRate, setInterestRate] = useState(initialInterestRate);
  const [paymentType, setPaymentType] = useState(initialPaymentType);

  const isFilled = (currentValue, initialValue) => {
    return currentValue !== initialValue ? "filled" : "";
  };

  const isSpanBold = (currentValue, initialValue) => {
    return currentValue !== initialValue ? "filled" : "";
  };

  const calculateAnnuityPayment = () => {
    const loanAmount = price - downPayment;
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = mortgagePeriod;

    const annuityPayment =
      (loanAmount *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
      (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

    return annuityPayment;
  };

  const calculateDifferentiatedPayment = () => {
    const loanAmount = price - downPayment;
    const monthlyInterestRate = interestRate / 100 / 12;
    const principalPayment = loanAmount / mortgagePeriod;

    const firstMonthPayment =
      principalPayment + loanAmount * monthlyInterestRate;

    return firstMonthPayment;
  };

  const monthlyPayment =
    paymentType === "annuity"
      ? calculateAnnuityPayment()
      : calculateDifferentiatedPayment();

  useEffect(() => {
    setMortgageData({
      propertyCost: price,
      initialPayment: downPayment,
      loanPeriod: mortgagePeriod,
      interestRate: interestRate,
      monthlyPayment: monthlyPayment,
      paymentType: paymentType,
    });
  }, [
    price,
    downPayment,
    mortgagePeriod,
    interestRate,
    monthlyPayment,
    paymentType,
    setMortgageData,
  ]);

  const handleReset = () => {
    setPrice(initialPrice);
    setDownPayment(initialDownPayment);
    setMortgagePeriod(initialMortgagePeriod);
    setInterestRate(initialInterestRate);
    setPaymentType(initialPaymentType);
  };

  const formatNumberWithSpaces = (number) => {
    return new Intl.NumberFormat("ru-RU")
      .format(number)
      .replace(/\u00A0/g, " ");
  };

  const handlePriceChange = (e) => {
    const inputValue = e.target.value.replace(/\s+/g, "");
    if (inputValue === "" || !isNaN(inputValue)) {
      setPrice(inputValue === "" ? "" : parseInt(inputValue));
    }
  };

  const [downPaymentPercentage, setDownPaymentPercentage] = useState(
    (
      (initialDownPayment / (initialPrice > 0 ? initialPrice : 1)) *
      100
    ).toString()
  );
  const [isEditingPercentage, setIsEditingPercentage] = useState(false);
  const formatPercentage = (value) => {
    const num = parseFloat(value);
    if (!isNaN(num)) {
      return num.toFixed(2);
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (!isEditingPercentage) {
      if (price > 0 && downPayment !== "") {
        const newPercentage = ((downPayment / price) * 100).toFixed(2);
        setDownPaymentPercentage(newPercentage);
      } else {
        setDownPaymentPercentage("");
      }
    }
  }, [downPayment, price, isEditingPercentage]);

  const handlePercentageChange = (e) => {
    const value = e.target.value.replace(",", ".");
    setDownPaymentPercentage(value);

    const percentage = parseFloat(value);

    if (!isNaN(percentage) && percentage >= 0 && percentage <= 100) {
      const newDownPayment = (price * percentage) / 100;
      setDownPayment(newDownPayment);
    }
  };

  const handlePercentageBlur = () => {
    setIsEditingPercentage(false);

    if (downPaymentPercentage !== "") {
      const percentage = parseFloat(downPaymentPercentage);
      if (!isNaN(percentage)) {
        const formattedPercentage = percentage.toFixed(2);
        setDownPaymentPercentage(formattedPercentage);
      }
    }
  };

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (isEditingPercentage) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [isEditingPercentage]);

  return (
    <div className="card mortgage-card">
      <h2>Ипотека</h2>

      <div className="upper-section">
        <div className="left-column-mortgage">
          <div className="field">
            <label>Стоимость объекта</label>
            <div className="input-wrapper">
              <div className="slider-input">
                <input
                  type="text"
                  value={price === "" ? "" : formatNumberWithSpaces(price)}
                  onChange={handlePriceChange}
                  step="100000"
                  min="1000000"
                  max="50000000"
                  placeholder="Введите стоимость объекта"
                  className={`slider-input-field interest-input ${isFilled(
                    price,
                    initialPrice
                  )}`}
                  // style={{
                  //   width: `${Math.max(price.toString().length * 0.7, 5)}em`,
                  // }}
                />
                <span
                  className={`currency-sign ${isFilled(price, initialPrice)}`}
                >
                  ₽
                </span>
                <svg
                  width="26"
                  height="23"
                  viewBox="0 0 26 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.0406 6.10994L15.9408 14.2106C15.2306 14.9199 13.9835 15.8494 13.1003 16.3263L11.442 17.2217C11.0755 17.4199 10.7497 17.5158 10.4483 17.5158C10.0363 17.5158 9.68045 17.3289 9.47245 17.0023C9.21001 16.5904 9.2287 16.0566 9.5277 15.4163L10.3695 13.6158C10.7993 12.6944 11.7003 11.4083 12.4202 10.6884L20.52 2.58775L24.0406 6.10994ZM25.2203 4.931L25.2658 4.8855C25.7343 4.41805 25.9983 3.78385 25.9998 3.12203C26.0013 2.4602 25.7403 1.82479 25.274 1.35519C24.3363 0.416752 22.6861 0.421627 21.7445 1.36413L21.699 1.40881L25.2203 4.931ZM20.3689 21.5158C20.3698 21.2946 20.2828 21.0821 20.1271 20.925C19.9714 20.768 19.7597 20.6791 19.5385 20.6781L0.837201 20.5911H0.833951C0.617228 20.5973 0.411433 20.6877 0.260148 20.843C0.108864 20.9983 0.023968 21.2064 0.02344 21.4232C0.0229119 21.64 0.106793 21.8485 0.257319 22.0045C0.407846 22.1606 0.613198 22.2519 0.829888 22.2592L19.5312 22.3453H19.5353C19.7558 22.3457 19.9675 22.2586 20.1238 22.1031C20.2801 21.9475 20.3682 21.7363 20.3689 21.5158Z"
                    fill="#E7E9F0"
                  />
                </svg>
              </div>
              <CustomSlider
                min={1000000}
                max={50000000}
                value={price === "" ? 0 : price}
                onChange={(value) => setPrice(value)}
              />
            </div>
          </div>

          <div className="field-two">
            <label>Период ипотеки</label>
            <div className="input-wrapper period-wrapper">
              <div className="slider-input">
                <div className="period-input-container">
                  <input
                    type="number"
                    value={
                      Math.floor(mortgagePeriod / 12) === 0
                        ? ""
                        : Math.floor(mortgagePeriod / 12)
                    }
                    onChange={(e) => {
                      let years = parseInt(e.target.value);
                      let months = mortgagePeriod % 12;
                      if (isNaN(years)) {
                        years = 0;
                      } else if (years < 0) {
                        years = 0;
                      } else if (years > 30) {
                        years = 30;
                      }
                      setMortgagePeriod(years * 12 + months);
                    }}
                    step="1"
                    min="0"
                    max="30"
                    placeholder="0"
                    className={`slider-input-field ${isSpanBold(
                      Math.floor(mortgagePeriod / 12),
                      Math.floor(initialMortgagePeriod / 12)
                    )} year-month`}
                  />
                  <span
                    className={`years-label ${isSpanBold(
                      Math.floor(mortgagePeriod / 12),
                      Math.floor(initialMortgagePeriod / 12)
                    )}`}
                  >
                    лет
                  </span>
                </div>

                <div className="period-input-container">
                  <input
                    type="number"
                    value={mortgagePeriod % 12 === 0 ? "" : mortgagePeriod % 12}
                    onChange={(e) => {
                      let months = parseInt(e.target.value);
                      let years = Math.floor(mortgagePeriod / 12);
                      if (isNaN(months)) {
                        months = 0;
                      } else if (months < 0) {
                        months = 0;
                      } else if (months > 11) {
                        months = 11;
                      }
                      setMortgagePeriod(years * 12 + months);
                    }}
                    step="1"
                    min="0"
                    max="11"
                    placeholder="0"
                    className={`slider-input-field ${isSpanBold(
                      mortgagePeriod % 12,
                      initialMortgagePeriod % 12
                    )} year-month`}
                  />
                  <span
                    className={`months-label ${isSpanBold(
                      mortgagePeriod % 12,
                      initialMortgagePeriod % 12
                    )}`}
                  >
                    мес
                  </span>
                </div>
              </div>

              <CustomSlider
                min={60}
                max={360}
                step={1}
                value={mortgagePeriod === "" ? 0 : mortgagePeriod}
                onChange={(value) => setMortgagePeriod(value)}
              />
            </div>
          </div>
        </div>

        <div className="right-column-mortgage">
          <div className="field">
            <label>Первоначальный взнос</label>
            <div className="input-wrapper">
              <div className="input-percent">
                <div className="slider-input">
                  <input
                    type="text"
                    value={
                      downPayment === ""
                        ? ""
                        : formatNumberWithSpaces(downPayment)
                    }
                    onChange={(e) => {
                      const value = e.target.value.replace(/\s+/g, "");
                      setDownPayment(value === "" ? "" : parseInt(value));
                    }}
                    step="100000"
                    min="1000000"
                    max="20000000"
                    placeholder="Введите первоначальный взнос"
                    className={`slider-input-field interest-input ${isFilled(
                      downPayment,
                      initialDownPayment
                    )}`}
                    // style={{
                    //   width: `${Math.max(downPayment.toString().length * 0.7, 5)}em`,
                    // }}
                  />
                  <span
                    className={`currency-sign ${isFilled(
                      downPayment,
                      initialDownPayment
                    )}`}
                  >
                    ₽
                  </span>
                  <svg
                    width="26"
                    height="23"
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.0406 6.10994L15.9408 14.2106C15.2306 14.9199 13.9835 15.8494 13.1003 16.3263L11.442 17.2217C11.0755 17.4199 10.7497 17.5158 10.4483 17.5158C10.0363 17.5158 9.68045 17.3289 9.47245 17.0023C9.21001 16.5904 9.2287 16.0566 9.5277 15.4163L10.3695 13.6158C10.7993 12.6944 11.7003 11.4083 12.4202 10.6884L20.52 2.58775L24.0406 6.10994ZM25.2203 4.931L25.2658 4.8855C25.7343 4.41805 25.9983 3.78385 25.9998 3.12203C26.0013 2.4602 25.7403 1.82479 25.274 1.35519C24.3363 0.416752 22.6861 0.421627 21.7445 1.36413L21.699 1.40881L25.2203 4.931ZM20.3689 21.5158C20.3698 21.2946 20.2828 21.0821 20.1271 20.925C19.9714 20.768 19.7597 20.6791 19.5385 20.6781L0.837201 20.5911H0.833951C0.617228 20.5973 0.411433 20.6877 0.260148 20.843C0.108864 20.9983 0.023968 21.2064 0.02344 21.4232C0.0229119 21.64 0.106793 21.8485 0.257319 22.0045C0.407846 22.1606 0.613198 22.2519 0.829888 22.2592L19.5312 22.3453H19.5353C19.7558 22.3457 19.9675 22.2586 20.1238 22.1031C20.2801 21.9475 20.3682 21.7363 20.3689 21.5158Z"
                      fill="#E7E9F0"
                    />
                  </svg>
                </div>

                <div className="tooltip-wrapper">
                  {isEditingPercentage ? (
                    <input
                      type="text"
                      value={downPaymentPercentage}
                      onChange={handlePercentageChange}
                      onBlur={handlePercentageBlur}
                      placeholder="%"
                      className="slider-input-percent"
                      autoFocus
                    />
                  ) : (
                    <div
                      className="slider-input-percent highlight-blue"
                      onClick={() => setIsEditingPercentage(true)}
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onTouchStart={() => setShowTooltip(true)}
                      onTouchEnd={() => setShowTooltip(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <strong>
                        {formatPercentage(downPaymentPercentage)}%
                      </strong>
                    </div>
                  )}
                  {showTooltip && (
                    <div className="tooltip-info">
                      <strong>Процент первоначального взноса</strong>
                      <p>
                        Процент от общей стоимости объекта, внесенный в качестве
                        первого платежа. Нажмите, чтобы изменить значение.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <CustomSlider
                min={1000000}
                max={20000000}
                value={downPayment === "" ? 0 : downPayment}
                onChange={(value) => setDownPayment(value)}
              />
            </div>
          </div>

          <div className="field-two">
            <label>Процентная ставка годовых</label>
            <div className="input-wrapper">
              <div className="interest-rate-input">
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                  step="0.001"
                  min="0"
                  max="1"
                  placeholder="Введите процентную ставку"
                  className={`interest-input ${isFilled(
                    interestRate,
                    initialInterestRate
                  )}`}
                  style={{
                    width: `${Math.max(interestRate.toString().length + 3)}ch`,
                  }}
                />
                <span
                  className={`percent-sign ${isFilled(
                    interestRate,
                    initialInterestRate
                  )}`}
                >
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lower-section">
        <div className="left-lower-mortgage">
          <div className="field-one">
            <label>Ежемесячный платёж</label>
            <div className="input-wrapper">
              <span className="month-pay">
                <strong>
                  {isNaN(monthlyPayment) ||
                  !isFinite(monthlyPayment) ||
                  monthlyPayment <= 0
                    ? "0"
                    : new Intl.NumberFormat("ru-RU")
                        .format(monthlyPayment)
                        .replace(/\u00A0/g, " ")}{" "}
                  ₽
                </strong>
              </span>
            </div>
          </div>

          <div className="checkboxes-row">
            <label className="custom-radio">
              <input
                type="radio"
                name="paymentType"
                value="annuity"
                checked={paymentType === "annuity"}
                onChange={() => setPaymentType("annuity")}
              />
              <span className="custom-radio-box"></span>
              <span className="radio-text">Аннуитетный</span>
            </label>
            <label className="custom-radio">
              <input
                type="radio"
                name="paymentType"
                value="differentiated"
                checked={paymentType === "differentiated"}
                onChange={() => setPaymentType("differentiated")}
              />
              <span className="custom-radio-box"></span>
              <span className="radio-text">Дифференцированный</span>
            </label>
          </div>
        </div>

        <div className="right-lower-mortgage">
          {/* <button className="download-pdf">
            Скачать <strong>PDF</strong>
          </button> */}
          <button className="reset" onClick={handleReset}>
            Сбросить расчет
          </button>
        </div>
      </div>
    </div>
  );
}

export default MortgageCard;
