import React, { useEffect, useState, useMemo } from "react";
import "../App.css";

const OFFSET_X = 10;
const OFFSET_Y = 10;

const CustomBarChart = ({ paymentSchedule, interval }) => {
  const [animate, setAnimate] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    x: 0,
    y: 0,
    value: 0,
  });

  useEffect(() => {
    setAnimate(true);
  }, []);

  const SVG_WIDTH = "100%";
  const SVG_HEIGHT = 400;

  const CHART_HEIGHT = 400;

  const parseDateFromString = (dateStr) => {
    const months = {
      январь: "01",
      февраль: "02",
      март: "03",
      апрель: "04",
      май: "05",
      июнь: "06",
      июль: "07",
      август: "08",
      сентябрь: "09",
      октябрь: "10",
      ноябрь: "11",
      декабрь: "12",
    };

    dateStr = dateStr.trim().toLowerCase().replace("г.", "").trim();

    const [monthName, year] = dateStr.split(" ");

    const monthNumber = months[monthName];

    if (!monthNumber || !year) {
      console.error("Некорректная дата:", dateStr);
      return null;
    }

    const dateString = `${year}-${monthNumber}-01`;
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error("Некорректная дата:", dateString);
      return null;
    }

    return date;
  };

  const aggregateWeeklyData = (data) => {
    const weeklyAggregation = [];
    let weekIndex = 1;
    let netIncomeSum = 0;

    for (let i = 0; i < data.length; i++) {
      netIncomeSum += parseFloat(data[i].netIncome) || 0;

      if ((i + 1) % 7 === 0 || i === data.length - 1) {
        weeklyAggregation.push({
          name: `Неделя ${weekIndex}`,
          netIncome: netIncomeSum,
        });
        weekIndex++;
        netIncomeSum = 0;
      }
    }

    return weeklyAggregation;
  };

  const aggregateMonthlyData = (data) => {
    const monthlyAggregation = {};

    data.forEach((row) => {
      const date = parseDateFromString(row.month);

      if (!date) {
        console.error("Некорректная дата:", row.month);
        return;
      }

      const monthKey = `${date.getFullYear()}-${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}`;

      if (!monthlyAggregation[monthKey]) {
        monthlyAggregation[monthKey] = {
          name: monthKey,
          netIncome: 0,
        };
      }
      monthlyAggregation[monthKey].netIncome += parseFloat(row.netIncome) || 0;
    });

    return Object.values(monthlyAggregation).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  };

  const aggregateYearlyData = (data) => {
    const yearlyAggregation = {};

    data.forEach((row) => {
      const date = parseDateFromString(row.month);

      if (!date) {
        console.error("Некорректная дата:", row.month);
        return;
      }

      const yearKey = date.getFullYear();

      if (!yearlyAggregation[yearKey]) {
        yearlyAggregation[yearKey] = {
          name: `${yearKey}`,
          netIncome: 0,
        };
      }
      yearlyAggregation[yearKey].netIncome += parseFloat(row.netIncome) || 0;
    });

    return Object.values(yearlyAggregation).sort((a, b) => a.name - b.name);
  };

  const filteredData = useMemo(() => {
    let data = [];

    if (!paymentSchedule || paymentSchedule.length === 0) {
      console.error("paymentSchedule пуст или не определен");
      return data;
    }

    const startDate = parseDateFromString(paymentSchedule[0].month);
    if (!startDate) {
      console.error("Не удалось распарсить стартовую дату");
      return data;
    }

    const getEndOfYearDate = (year) => {
      return new Date(year, 11, 31);
    };

    switch (interval) {
      case "30 дней":
        const first30Days = paymentSchedule.slice(0, 30);
        data = aggregateWeeklyData(first30Days);
        break;
      case "1 год":
        const endOfFirstYear = getEndOfYearDate(startDate.getFullYear());
        data = paymentSchedule.filter((row) => {
          const date = parseDateFromString(row.month);
          return date >= startDate && date <= endOfFirstYear;
        });
        data = aggregateMonthlyData(data);
        break;
      case "3 года":
        const endOfThirdYear = getEndOfYearDate(startDate.getFullYear() + 2);
        data = paymentSchedule.filter((row) => {
          const date = parseDateFromString(row.month);
          return date >= startDate && date <= endOfThirdYear;
        });
        data = aggregateYearlyData(data);
        break;
      case "10 лет":
        const endOfTenthYear = getEndOfYearDate(startDate.getFullYear() + 9);
        data = paymentSchedule.filter((row) => {
          const date = parseDateFromString(row.month);
          return date >= startDate && date <= endOfTenthYear;
        });
        data = aggregateYearlyData(data);
        break;
      case "Весь срок":
        data = aggregateYearlyData(paymentSchedule);
        break;
      default:
        data = aggregateMonthlyData(paymentSchedule);
        break;
    }

    return data;
  }, [paymentSchedule, interval]);

  const maxValue = useMemo(() => {
    const values = filteredData.map((d) => Math.abs(d.netIncome));
    return values.length > 0 ? Math.max(...values) : 0;
  }, [filteredData]);

  const chartWidth = Math.max(700, filteredData.length * 80);

  const BAR_WIDTH = (chartWidth / filteredData.length) * 0.8;
  const gap = (chartWidth / filteredData.length) * 0.2;

  const scaleX = (index) => OFFSET_X + index * (BAR_WIDTH + gap);

  const scaleY = (value) => {
    return maxValue !== 0 ? ((value / maxValue) * CHART_HEIGHT) / 2 : 0;
  };

  const formatXAxisLabel = (name) => {
    if (interval === "1 год") {
      const [year, month] = name.split("-");
      return `${month}.${year}`;
    }
    return name;
  };

  const handleBarClick = (e, value) => {
    e.preventDefault();
  
    const rect = e.currentTarget.getBoundingClientRect();
    const tooltipWidth = 150;
    const tooltipHeight = 50;
    const offset = 10;
  
    // Since we're using position: fixed, no need to adjust for scroll
    let tooltipX = rect.left + rect.width / 2 - tooltipWidth / 2;
    let tooltipY = rect.top - tooltipHeight - offset;
  
    // Ensure the tooltip stays within the viewport
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
  
    if (tooltipX + tooltipWidth > windowWidth) {
      tooltipX = windowWidth - tooltipWidth - offset;
    }
    if (tooltipX < 0) {
      tooltipX = offset;
    }
    if (tooltipY < 0) {
      tooltipY = rect.bottom + offset;
    }
  
    setTooltip((prevTooltip) => ({
      visible: !prevTooltip.visible || prevTooltip.value !== value,
      x: tooltipX,
      y: tooltipY,
      value,
    }));
  };  

  return (
    <div className="chart-container">
      <svg
        width={chartWidth + OFFSET_X * 2}
        height={SVG_HEIGHT}
        viewBox={`0 0 ${chartWidth + OFFSET_X * 2} ${
          CHART_HEIGHT + OFFSET_Y * 2
        }`}
      >
        <defs>
          <pattern
            id="diagonalHatch"
            patternUnits="userSpaceOnUse"
            width="10"
            height="10"
            patternTransform="rotate(45)"
          >
            <line x1="0" y1="0" x2="0" y2="10" stroke="#ccc" strokeWidth="2" />
          </pattern>
        </defs>

        <line
          x1={OFFSET_X}
          y1={SVG_HEIGHT / 2}
          x2={chartWidth - OFFSET_X}
          y2={SVG_HEIGHT / 2}
          stroke="rgba(211, 211, 211, 0.5)"
          strokeWidth="1"
        />

        {filteredData.map((d, i) => (
          <g key={`${d.name}-${i}`}>
            {d.netIncome >= 0 ? (
              <rect
                x={scaleX(i)}
                y={SVG_HEIGHT / 2 - scaleY(d.netIncome)}
                width={BAR_WIDTH}
                height={animate ? scaleY(d.netIncome) : 0}
                fill="rgba(130, 234, 195, 1)"
                rx="10"
                className={`bar animate-bar`}
                style={{
                  transformOrigin: `center bottom`,
                  transition: `height 0.5s ease ${i * 0.2}s`,
                }}
                onMouseDown={(e) => handleBarClick(e, d.netIncome)}
                onTouchStart={(e) => handleBarClick(e, d.netIncome)}
              />
            ) : (
              <rect
                x={scaleX(i)}
                y={SVG_HEIGHT / 2}
                width={BAR_WIDTH}
                height={animate ? scaleY(-d.netIncome) : 0}
                fill="url(#diagonalHatch)"
                rx="10"
                stroke="rgba(231, 233, 240, 1)"
                strokeWidth="1"
                className={`bar animate-bar`}
                style={{ transition: `height 0.5s ease ${i * 0.2}s` }}
                onMouseDown={(e) => handleBarClick(e, d.netIncome)}
                onTouchStart={(e) => handleBarClick(e, d.netIncome)}
              />
            )}
            <text
              x={scaleX(i) + BAR_WIDTH / 2}
              y={SVG_HEIGHT / 2 + 20}
              textAnchor="middle"
              fontSize="12"
              fill="#333"
            >
              {formatXAxisLabel(d.name)}
            </text>
          </g>
        ))}
      </svg>

      {tooltip.visible && (
        <div
          className="tooltip"
          style={{ left: `${tooltip.x}px`, top: `${tooltip.y}px` }}
        >
          {`${new Intl.NumberFormat("ru-RU").format(tooltip.value.toFixed(2))} ₽`}
        </div>
      )}
    </div>
  );
};

export default CustomBarChart;
