import React, { useState, useContext } from "react";
import "../App.css";
import DetailCard from "./DetailCard";
import PaymentSchedule from "./PaymentSchedule";
import Chart from "./Chart";
import { DataContext } from "../contexts/data.context";

function Stepper({
  mortgageData,
  rentalData,
  realizationData,
  isDepositChecked,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const { paymentSchedule } = useContext(DataContext);

  const renderContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <DetailCard
            mortgageData={mortgageData}
            rentalData={rentalData}
            realizationData={realizationData}
            isDepositChecked={isDepositChecked}
          />
        );
      case 1:
        return (
          <PaymentSchedule
            mortgageData={mortgageData}
            rentalData={rentalData}
            realizationData={realizationData}
          />
        );
      case 2:
        return <Chart paymentSchedule={paymentSchedule} />;
      default:
        return null;
    }
  };

  return (
    <div className="stepper">
      <div className="stepper-buttons">
        <button
          className={activeStep === 0 ? "active" : ""}
          onClick={() => setActiveStep(0)}
        >
          Детализация
        </button>
        <button
          className={activeStep === 1 ? "active" : ""}
          onClick={() => setActiveStep(1)}
        >
          График платежей
        </button>
        <button
          className={activeStep === 2 ? "active" : ""}
          onClick={() => setActiveStep(2)}
        >
          Доход/убыток
        </button>
      </div>
      <div className="stepper-content">{renderContent()}</div>
    </div>
  );
}

export default Stepper;
