import React from 'react';
import '../App.css';
import logo from '../img/logo.svg';

function Header() {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Логотип" />
      </div>
    </header>
  );
}

export default Header;
